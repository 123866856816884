import React from 'react';

import {
    button,
    active,
    yellow,
    purple,
    pink,
    blue,
} from './project-category-navigation-item.module.scss';
import { IProjectCategory } from '../../models/project';
import { TAccentColor } from '../../models/accent-color.model';

export interface IProjectCategoryNavigationItemProps {
    className?: string;
    projectCategory: IProjectCategory;
    color: TAccentColor;
    isActive?: boolean;
    onCategoryClick?(offerCategoryId: number): void;
}

const ProjectCategoryNavigationItem: React.FC<IProjectCategoryNavigationItemProps> = ({
    className = '',
    projectCategory,
    color,
    isActive = false,
    onCategoryClick,
}) => {
    const { categoryId } = projectCategory;

    const colorClass = colorClasses[color];

    const handleCategoryClick = () => {
        if (typeof onCategoryClick !== 'function') return;
        onCategoryClick(categoryId);
    };

    return (
        <button
            className={`${className} ${button} ${colorClass} ${isActive ? active : ''}`}
            onClick={handleCategoryClick}
        >
            {projectCategory.name}
        </button>
    );
};

const colorClasses: Record<TAccentColor, string> = {
    yellow,
    purple,
    pink,
    blue,
};

export default ProjectCategoryNavigationItem;
