// extracted by mini-css-extract-plugin
export var clientName = "project-card-module--client-name--YLXoG";
export var container = "project-card-module--container--SnSu+";
export var header = "project-card-module--header--+-Da8";
export var imageBackground = "project-card-module--image-background--BI6ag";
export var imageBox = "project-card-module--image-box--SRknD";
export var imageContainer = "project-card-module--image-container--XZnh9";
export var leadText = "project-card-module--lead-text--YlzJZ";
export var logoBox = "project-card-module--logo-box--zUQz6";
export var logoRatio = "project-card-module--logo-ratio--rPGEh";
export var numberBox = "project-card-module--number-box--HK2WL";
export var ratio = "project-card-module--ratio--opLnO";