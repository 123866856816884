import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { IPageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    layout,
    hero,
    navigation,
    section,
    item,
    content,
    buttonPdf,
} from './portfolio.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IProject, IProjectCategory } from '../models/project';
import { TAccentColor } from '../models/accent-color.model';
import { getColorInSequence } from '../utils/get-color-in-sequence';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import ProjectCategoryNavigationItem, {
    IProjectCategoryNavigationItemProps,
} from '../components/atoms/project-category-navigation-item';
import ProjectCard from '../components/molecules/project-card';
import Button from '../components/atoms/button';
import staticFiles from '../config/static-files';

interface IPortfolioPageProps {
    readonly pageContext: IPageContext;
    readonly data: {
        allProjectCategory: IQueryAllResult<IProjectCategory>;
        allProject: IQueryAllResult<IProject>;
    };
}

const PortfolioPage: React.FC<IPortfolioPageProps> = ({ pageContext, data }) => {
    const { t } = useT();
    const { locale } = pageContext;
    const { allProjectCategory, allProject } = data;
    const projects = getNodes(allProject);
    const projectCategories = getNodes(allProjectCategory);

    const categoryAll: IProjectCategory = {
        locale: locale,
        categoryId: 0,
        sequence: 0,
        name: t('project.category.all.name'),
    };
    const [activeCategoryId, setActiveCategoryId] = useState(categoryAll.categoryId);

    const projectsInCategory = getProjectsFromCategory(projects, activeCategoryId, categoryAll);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pdfLink = staticFiles.portfolio[locale];

    const handleCategoryClick: IProjectCategoryNavigationItemProps['onCategoryClick'] = (
        projectCategoryId
    ) => {
        setActiveCategoryId(projectCategoryId);
    };

    return (
        <MainLayout className={layout} showFloatingCircles={true}>
            <Hero
                className={hero}
                titleProps={{ children: t('portfolio.hero.title'), Tag: 'h1' }}
                circleDisplay="none"
            />
            <Section
                theme="dark"
                circleDisplay="none"
                height="auto"
                contentDisplay="center"
                className={section}
                gridType="narrow"
            >
                <div className={content}>
                    <Button
                        className={buttonPdf}
                        as="externalLink"
                        href={pdfLink}
                        target="_blank"
                        rel="noreferrer noopener"
                        kind="normalDark"
                        color="yellow"
                    >
                        {t('button.see.pdf')}
                    </Button>
                    <ul className={navigation}>
                        {[categoryAll, ...projectCategories].map((projectCategory, index) => {
                            const color = getColorInSequence(index, colors);
                            const isActive = activeCategoryId === projectCategory.categoryId;
                            return (
                                <li key={projectCategory.categoryId}>
                                    <ProjectCategoryNavigationItem
                                        color={color}
                                        isActive={isActive}
                                        projectCategory={projectCategory}
                                        onCategoryClick={handleCategoryClick}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    <ul>
                        <AnimatePresence exitBeforeEnter={true}>
                            {projectsInCategory.map((project, index) => {
                                return (
                                    <motion.li
                                        key={`project-item-${project.projectId}-${activeCategoryId}`}
                                        variants={projectItemVariants}
                                        className={item}
                                        initial="initial"
                                        animate="enter"
                                        exit="leave"
                                    >
                                        <ProjectCard projectCard={project} number={index + 1} />
                                    </motion.li>
                                );
                            })}
                        </AnimatePresence>
                    </ul>
                </div>
            </Section>
        </MainLayout>
    );
};

function getProjectsFromCategory(
    projects: IProject[],
    categoryId: number,
    categoryAll: IProjectCategory
): IProject[] {
    return projects.filter((project) => {
        return (
            categoryId === categoryAll.categoryId ||
            project.categories.some((category) => category.categoryId === categoryId)
        );
    });
}

const projectItemVariants: Variants = {
    initial: {
        opacity: 0,
        x: '100vw',
    },
    enter: {
        opacity: 1,
        x: '0',
        transition: {
            duration: 0.3,
            ease: 'easeOut',
        },
    },
    leave: {
        opacity: 0,
        x: '-100vw',
        transition: {
            duration: 0.3,
            ease: 'easeIn',
        },
    },
};

const colors: TAccentColor[] = ['pink', 'yellow', 'purple', 'blue'];

export const query = graphql`
    query PortfolioPage($locale: String!) {
        allProjectCategory(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...projectCategoryFields
                }
            }
        }
        allProject(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...projectFields
                }
            }
        }
    }
`;

export default PortfolioPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
