const staticFiles = {
    privacy: {
        en: '/docs/privacy-policy.pdf',
        pl: '/docs/polityka-prywatnosci.pdf',
    },
    portfolio: {
        en: '/docs/portfolio-alterpage-short.pdf',
        pl: '/docs/portfolio-alterpage-short.pdf',
    },
};

export default staticFiles;
